import { CategoryModel } from '@/models';

export enum SliderMediaType {
  Image = 'IMAGE',
  Video = 'VIDEO',
}
export type ImageSlider = {
  image_url?: string;
  video_url?: string;
  title: string;
  link?: string;
  media_type: SliderMediaType;
  metadata?: {
    mime_type?: string;
  };
};
export type HomeSliderProps = {
  images: ImageSlider[];
};
type Images = {
  url: string;
};
export interface CategoryType extends CategoryModel {
  images: Images;
}
export interface CategoryPartialsProps {
  categories: CategoryType[];
}
export type HomeConstantType = {
  type: string;
  description: string;
};
export interface FlashSalePartialsProps {
  
};
