import React from 'react';

import { Product } from '@/features/products/shared';
import { AuctionStatus, DiscountStatus, PagePath, PriceSymbol, ProductStatus } from '@/layouts/shared';
import { checkTime, createHandle, toMoney } from '@/libs/app';
import { Link, Image, PentagonIcon } from '@/components';
import { TFunction } from 'next-i18next';
import NewGallery from '../ui/NewGallery';
import SkeletonLoading from '../ui/SkeletonLoading';

type ProductLoopProps = {
  p: Product;
  t: TFunction;
  catCode?: string;
  isShowOriginPrice?: boolean;
};
export const ProductLoop = (props: ProductLoopProps) => {
  const { p, t, catCode, isShowOriginPrice } = props;
  let handle = `${createHandle(p.product_name)}-${p.id}-${p.product_code}`;
  let isDiscount = p.product_discount ? checkTime(p.product_discount?.started_at, p.product_discount?.ended_at) : false;
  if (p.product_discount && p.product_discount?.status === DiscountStatus.Ended) isDiscount = false;
  const isDeposited = p.quantity <= 0 || p.status == ProductStatus.CloseASale || p.status === ProductStatus.Deposited;
  return (
    <div className="tw-w-full tw-h-full tw-bg-white">
      <div className="tw-flex tw-flex-col tw-rounded-5 tw-border tw-border-gray-70 tw-overflow-hidden tw-w-full tw-h-full hover:tw-border-primary-main tw-duration-500 tw-ease-linear">
        <div className="tw-w-full p-100p tw-relative tw-leading-0 tw-h-0 tw-bg-secondary-20">
          {p.images && p.images.length > 1 ? (
            <div className="tw-w-full tw-h-full !tw-absolute tw-z-1 tw-top-0 tw-left-0 tw-bottom-0">
              <NewGallery images={p.images} />
            </div>
          ) : (
            <Image
              className="tw-w-full tw-h-full !tw-absolute tw-z-1 tw-top-0 tw-left-0 tw-bottom-0"
              alt={p.product_name}
              src={p.images && p.images[0] ? p.images[0].url : '/bg-default.png'}
            ></Image>
          )}
          {p.product_discount && isDiscount && (
            <>
              <div className="tw-w-7 tw-h-7 tw-absolute tw-top-3 tw-right-3 tw-z-10">
                <Image src="/sale.png" className="tw-w-full tw-h-full" alt="sale"></Image>
              </div>
            </>
          )}
          {p.is_auction && (
            <>
              <div className="tw-w-7 tw-h-7 tw-absolute tw-top-3 tw-right-3 tw-z-10">
                <Image src="/auction.png" className="tw-w-full tw-h-full" alt="auction"></Image>
              </div>
            </>
          )}
          {isDeposited && (
            <div
              className="tw-absolute tw-z-10"
              style={{
                top: '8px',
                left: '0',
              }}
            >
              <PentagonIcon text="Đã bán" />{' '}
            </div>
          )}
        </div>

        <div className="tw-pt-4 tw-pb-5">
          {catCode ? (
            <Link
              url={{ pathname: PagePath.CategoryProductPage, query: { id: catCode, slug: handle } }}
              className="tw-h-12 md:tw-h-12 tw-px-1 tw-text-base  tw-font-semibold tw-text-primary-main tw-w-full tw-line-clamp-2 tw-text-center"
            >
              {p.product_name}
            </Link>
          ) : (
            <Link
              url={{ pathname: PagePath.ProductDetailPage, query: { id: handle } }}
              className="tw-h-12 md:tw-h-12 tw-px-1 tw-text-base  tw-font-semibold tw-text-primary-main tw-w-full tw-line-clamp-2 tw-text-center"
            >
              {p.product_name}
            </Link>
          )}

          <div className="tw-text-center">
            <div className="tw-text-secondary-81 tw-font-light tw-text-sm md:tw-text-base tw-line-clamp-1 tw-px-1">
              {p.product_code}
            </div>
            {p.quotation ? (
              <span className="tw-text-secondary-70">Liên hệ</span>
            ) : (
              <>
                {p.is_auction && p.auction ? (
                  <>
                    <span className="tw-text-md tw-block tw-mt-1 tw-text-secondary-70 tw-font-semibold">
                      {toMoney(
                        p.auction.status === AuctionStatus.Pending
                          ? p.auction.origin_price || 0
                          : p.auction.sell_price || 0,
                        t,
                        PriceSymbol.$
                      )}
                    </span>
                  </>
                ) : (
                  <>
                    {p.product_discount && isDiscount ? (
                      <>
                        {isShowOriginPrice ? (
                          <span className="tw-block tw-mt-1 tw-text-secondary-81 tw-font-light tw-text-sm tw-line-through tw-italic">
                            {toMoney(p.price, t, PriceSymbol.$)}
                          </span>
                        ) : null}
                        <span className="tw-text-md tw-block tw-mt-1 tw-text-secondary-70 tw-font-semibold">
                          {toMoney(p.price - (p.price * p.product_discount.discount) / 100, t, PriceSymbol.$)}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="tw-text-md tw-block tw-mt-1 tw-text-secondary-70 tw-font-semibold">
                          {toMoney(p.price, t, PriceSymbol.$)}
                        </span>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProductLoopLoading = () => {
  return (
    <div className="tw-w-full tw-h-full tw-bg-white tw-border tw-border-gray-70">
      <div className="tw-flex tw-flex-col tw-rounded-5 tw-overflow-hidden tw-w-full tw-h-full">
        <div className="tw-w-full p-100p tw-relative tw-h-0 ">
          <SkeletonLoading className="tw-w-full tw-h-full tw-absolute tw-top-0 tw-left-0" />
        </div>

        <div className="tw-pt-4 tw-pb-5 tw-px-2">
          <SkeletonLoading className="tw-h-12 md:tw-h-12 tw-rounded"></SkeletonLoading>

          <div className="tw-text-center tw-mt-1">
            <SkeletonLoading className="tw-h-6 tw-rounded"></SkeletonLoading>
            <SkeletonLoading className="tw-h-6 tw-mt-1 tw-rounded"></SkeletonLoading>
          </div>
        </div>
      </div>
    </div>
  );
};
