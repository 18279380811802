import { pad, parseUtcTime } from '@/libs/app';
import { TFunction } from 'next-i18next';
import React, { useEffect, useState } from 'react';
type CountDownType = {
  startTime: string;
  endTime: string;
  handleCountDown?: () => void;
  t: TFunction;
};
export const CountDown = ({
  startTime,
  endTime,
  handleCountDown,
  t,
}: CountDownType) => {
  const countDownDate = new Date(endTime).getTime();
  const [show, setShow] = useState(true);
  const [hours, setHours] = useState<string>('0');
  const [minute, setMinute] = useState<string>('0');
  const [second, setSecond] = useState<string>('0');
  useEffect(() => {
    setShow(true);
    let distance = 0;
    const interval = setInterval(() => {
      var now = parseUtcTime();
      distance = countDownDate - now;
      distance = distance < 0 ? 0 : distance;

      setHours(pad(Math.floor(distance / (1000 * 60 * 60))));
      setMinute(pad(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))));
      setSecond(pad(Math.floor((distance % (1000 * 60)) / 1000)));

      if (Math.round(distance / 1000) <= 0) {
        setShow(false);
        clearInterval(interval);
        handleCountDown && handleCountDown();
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTime]);
  return (
    <>
      {show && (
        <div className="tw-text-white tw-flex tw-gap-x-2">
          <div className="tw-flex tw-flex-col  tw-text-center">
            <div className="tw-border-primary-main tw-bg-primary-main tw-border tw-text-white tw-font-semibold tw-flex tw-items-center tw-justify-center tw-text-lg tw-rounded-5 tw-w-8 tw-h-8">
              {hours}
            </div>
          </div>
          <div className="tw-text-primary-main tw-text-lg">:</div>
          <div className="tw-flex tw-flex-col tw-text-center">
            <div className="tw-border-primary-main tw-bg-primary-main tw-border tw-text-white tw-font-semibold tw-flex tw-items-center tw-justify-center tw-text-lg tw-rounded-5 tw-w-8 tw-h-8">
              {minute}
            </div>
          </div>
          <div className="tw-text-primary-main tw-text-lg">:</div>
          <div className="tw-flex tw-flex-col  tw-text-center">
            <div className="tw-border-primary-main tw-bg-primary-main tw-border tw-text-white tw-font-semibold tw-flex tw-items-center tw-justify-center tw-text-lg tw-rounded-5 tw-w-8 tw-h-8">
              {second}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
