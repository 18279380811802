import { useEffect, useRef, useState } from 'react';
import { Link, Image, Icon, ArrowLeftIcon } from '@/components';
import { PagePath } from '@/layouts/shared';
import { Product } from '@/features/products/shared';
import toast from 'react-hot-toast';
import { useFetchFlashSale } from '../../api';
import { ProductLoop } from '@/components/loop/ProductLoop';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation } from 'swiper';
import { TFunction } from 'next-i18next';
import { CountDown } from './CountDown';
import dayjs from 'dayjs';

type FlashSaleProps = {
  title: string;
  theme?: any;
  t: TFunction;
};
const FlashSale = ({ title, theme, t }: FlashSaleProps) => {
  const { icon_category_url } = theme ?? {};

  const [flashSaleProducts, setFlashSaleProducts] = useState<Product[]>([]);
  const [startTime, setStartTime] = useState<string>(dayjs().toISOString());
  const [endTime, setEndTime] = useState<string>(dayjs().toISOString());
  const swiperRef = useRef<any>();

  const { refetch } = useFetchFlashSale({
    enabled: false,
    refetchOnWindowFocus: false,
    onError: ({ message }) => toast.error(message),
    onSuccess: ({ data }) => {
      setFlashSaleProducts(data.products);
      setStartTime(data.started_at);
      setEndTime(data.ended_at);
    },
  });

  useEffect(() => {
    refetch();

    const interval = setInterval(() => {
      refetch();
    }, 60000);

    return () => clearInterval(interval);
  }, [refetch]);

  if (!flashSaleProducts?.length) return <></>;
  return (
    <section className="container tw-mx-auto tw-mb-5 md:tw-mb-10">
      <div className="tw-w-full">
        <div className="tw-flex tw-justify-between tw-items-center">
          <div className="tw-text-lg md:tw-text-2xl tw-flex tw-items-center tw-flex-1 tw-relative">
            <div className="tw-flex tw-items-center tw-w-max tw-justify-center ">
              {icon_category_url && (
                <Image
                  src={icon_category_url}
                  alt="star"
                  className="tw-h-8 tw-w-8"
                />
              )}
              {!icon_category_url && (
                <Image
                  src={'/diamond.svg'}
                  alt="star"
                  className="tw-h-5 tw-w-5"
                />
              )}
              <h2
                className={`tw-flex-1 tw-block tw-px-4 tw-uppercase tw-text-primary-main tw-font-semibold`}
              >
                {title}
              </h2>
              {!icon_category_url && (
                <Image
                  src={'/diamond.svg'}
                  alt="star"
                  className="tw-h-5 tw-w-5"
                />
              )}
            </div>
            <div className="tw-hidden md:tw-block">
              <CountDown
                startTime={startTime}
                endTime={endTime}
                t={t}
                handleCountDown={() => {
                  setTimeout(() => refetch(), 2000);
                }}
              />
            </div>
          </div>
          <div className="tw-text-center tw-text-md">
            <Link
              className="tw-italic tw-text-center tw-flex tw-items-center tw-w-max !tw-text-secondary-70"
              url={{ pathname: PagePath.CategoryPage, query: { id: 'FLASHSALE' } }}
            >
              <span>Xem thêm</span>
              <Icon
                className="tw-w-4 tw-h-4 tw-rotate-180 tw-ml-2"
                icon={<ArrowLeftIcon />}
              />
            </Link>
          </div>
        </div>
        <div className="tw-flex md:tw-hidden tw-justify-end tw-mt-5">
          <CountDown
            startTime={startTime}
            endTime={endTime}
            t={t}
            handleCountDown={() => {
              setTimeout(() => refetch(), 2000);
            }}
          />
        </div>
        {flashSaleProducts.length > 0 && (
          <div className="tw-block tw-relative tw-mt-8">
            <button
              className="tw-hidden custom-prev tw-w-10 tw-h-10 tw-rounded-full tw-absolute tw-top-1/4 tw--left-10 lg:tw-flex tw-items-center tw-justify-center tw-z-10 tw-text-primary-main"
              onClick={() => swiperRef.current?.slidePrev()}
            >
              <Icon className="tw-w-full tw-h-full" icon={<ArrowLeftIcon />} />
            </button>
            <Swiper
              slidesPerView={4}
              spaceBetween={16}
              keyboard={{
                enabled: true,
              }}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 2.5,
                  spaceBetween: 8,
                },
                768: {
                  slidesPerView: 3.5,
                  spaceBetween: 16,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 16,
                },
              }}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              modules={[EffectFade, Navigation]}
              className="product-loop-swiper tw-overflow-auto category-scrollbar"
              id="swiper"
            >
              {flashSaleProducts.map((item, key) => (
                <SwiperSlide key={`item-${key}`} className="tw-relative">
                  <ProductLoop
                    isShowOriginPrice={true}
                    catCode={item.cat_code}
                    p={item}
                    t={t}
                  ></ProductLoop>
                </SwiperSlide>
              ))}
            </Swiper>
            <button
              className="custom-next tw-hidden tw-w-10 tw-h-10 tw-absolute tw--right-10 tw-top-1/4 tw-text-primary-main lg:tw-flex tw-items-center tw-justify-center tw-rotate-180 tw-z-10"
              onClick={() => swiperRef.current?.slideNext()}
            >
              <Icon className="tw-w-full tw-h-full" icon={<ArrowLeftIcon />} />
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default FlashSale;
